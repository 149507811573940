import React from "react";
import { Helmet } from 'react-helmet';
import Main from "../layout/Main";
import EntBlue from "../assets/imgs/ent-blue.png";
import EntLight from "../assets/imgs/ent-light.png";
import EntMoney from "../assets/imgs/ent-money.png";
import EntNigeria from "../assets/imgs/ent-nigeria.png";
import EntSecure from "../assets/imgs/ent-secure.png";
import EntFree from "../assets/imgs/ent-free.png";
import Ent3dBadge from "../assets/imgs/ent-3d-badge.png";
import EntPhoneMan from "../assets/imgs/ent-phone-man.png";
import EntPhoneCode from "../assets/imgs/ent-phone-code.png";
import EntEs from "../assets/imgs/ent-es.png";
import EntPartnerShip from "../assets/imgs/ent-partnership.png";
import EntTeamwork from "../assets/imgs/ent-teamwork.png";
import EntCc from "../assets/imgs/ent-cc.png";
import EntIntegrity from "../assets/imgs/ent-integrity.png";
import EntTrust from "../assets/imgs/ent-trust.png";
import EntInnovation from "../assets/imgs/ent-innovation.png";
import EntEfficiency from "../assets/imgs/ent-efficiency.png";
import EntOutreach from "../assets/imgs/ent-outreach.png";
import EntConvenience from "../assets/imgs/ent-convenience.png";
import EntEffortless from "../assets/imgs/ent-effortless.png";
import EntLowTransfer from "../assets/imgs/ent-low-transfer.png";
import EntCheck from "../assets/imgs/ent-check.png";
import EntLdEc from "../assets/imgs/ent-ld-ec.png";
import EntLdEd from "../assets/imgs/ent-ld-ed.png";
import EntLdCs from "../assets/imgs/ent-ld-cs.png";
import EntLdBa from "../assets/imgs/ent-ld-ba.png";
import EntLdGm from "../assets/imgs/ent-ld-gm.png";
import EntDial from "../assets/imgs/ent-dial.png";
import EntSecureIcon from "../assets/imgs/secure-icon.png"
import Button from "../components/Button";
import EntWhoWeAreImg from "../assets/imgs/ent-who-we-are-img.png"
import "./styles.scss";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import ImageLabel from "./components/ImageLabel";
import StaticButton from "../components/Button/StaticButton";

const Home = () => {

  return (
    <Main>
      <Helmet>
        <title>Home | Entrust</title>
        <meta name="keywords" content="New Era" />
      </Helmet>
      <div className={"min-h-screen h-full"}>
        <section className="relative section-one xl:h-[52rem] lg:h-[40rem] sm:h-[35rem] h-[18rem]" id="headerSection">
          <div className="cursur-pointer">
            <img src={EntBlue} alt={"icon"} className="absolute right-[0] top-[8rem] z-[-1] ent-blue xl:h-auto lg:h-[35rem] h-[25rem] sm:block hidden" />
            <img src={EntLight} alt={"icon"} className="absolute right-[10rem] top-[8rem] z-[-1] ent-light xl:h-auto lg:h-[25rem] h-[18rem] sm:block hidden" />
            <img src={EntDial} alt={"icon"} className="absolute xl:right-[3.5rem] xl:top-[21.5rem] lg:right-[4rem] lg:top-[18.5rem] md:right-[2.5rem] md:top-[14.5rem] right-[1rem] top-[8rem] xl:h-auto lg:h-[10rem] md:h-[10rem] h-[4rem] z-[10]" />
          </div>
          <div className={"container"}>
            <div className={"flex flex-wrap"}>
              <div className={"w-full"}>
                <div className={"xl:pt-[16rem] sm:pt-[8rem] pt-[4rem] sm:pb-[4rem] pb-[2rem]"}>
                  <div className="w-7/12">
                    <p className={"text-body-xl sm:text-heading-sm xl:text-[52px] xl:leading-[76px] mt-6 font-normal text-black"}>
                    Safe, fast and secure way 
                    </p>
                    <p className={"text-body-xl sm:text-heading-sm xl:text-[52px] xl:leading-[76px] mt-6 font-normal text-black"}>
                    to send money to Nigeria
                    </p>
                    <p className={"xl:text-heading-xs sm:text-body-xl text-body-sm font-light sm:mt-6 mt-2 lh-5 text-black"}>
                    Easily send money from across the globe to Nigeria with Entrusted
                    </p>
                  </div>
                </div>
                <Button
                  state={"SECONDARY"}
                  text={"Get Started"}
                />
                <div>
                  
                </div>

                <div class="flex mt-[1rem]">
  <div>
  <img src={EntSecureIcon} alt={"icon"} 
   />
  </div>
  <div className="ml-[1rem]">
  <p className="text-[16px] font-bold font-avantMd">Stay informed, stay safe</p>
  <p>Protect yourself from fraud</p>
  </div>
</div>

              </div>
            </div>
          </div>
        </section>
        <section className="section-two relative xl:rounded-tl-[10rem] lg:rounded-tl-[6rem] rounded-tl-[4rem]">
          <div className={"container"}>
            <div className={"flex flex-wrap"}>
              <div className={"xl:pt-[10rem] pt-[3rem] pb-[3rem] w-full"}>
                <div className={"text-center"}>
                  <p className={"xl:font-light font-light sm:text-body-2xl text-body-xl xl:text-heading-lg mt-6 text-white inline-block"}>
                    Sending money <img src={EntMoney} alt={"icon"} className="inline-block xl:h-auto h-[3rem]" /> to beneficiaries in Nigeria <img src={EntNigeria} alt={"icon"} className="inline-block xl:h-auto h-[3rem]" /> can be complicated and stressful. At Entrusted, our reliable and secure <img src={EntSecure} alt={"icon"} className="inline-block xl:h-auto h-[3rem]" /> money transfer service is designed to make sending funds to your Nigerian beneficiaries easy and stress - free <img src={EntFree} alt={"icon"} className="inline-block xl:h-auto h-[3rem]" />
                  </p>
                </div>
                <div className="flex justify-center mt-[4rem]">
                  <Button
                    state={"PRIMARY"}
                    text={"Get Started"}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-three xl:mt-[4rem] mt-[2rem]" id="whyChooseEntrusted">
          <div className={"container xl:py-[6rem] sm:py-[4rem] py-[2rem]"}>
            <div className={"text-center"}>
              <p className="xl:text-[52px] lg:text-[40px] sm:text-[32px] text-[24px] font-light">Why Choose Entrusted?</p>
            </div>
          </div>
          <hr />
          <div className="why-choose">
            <div className={"container sm:py-[4rem] py-[2rem]"}>
              <div className="flex flex-wrap font-light">
                <div className="w-8/12 flex items-center">
                  <p className="xl:text-heading-sm lg:text-heading-xs text-body-xl">WIDE OUTREACH</p>
                </div>
                <div className="w-4/12 flex items-center">
                  <p>Our extensive network of partners and affiliates spans across various countries, ensuring that we provide you with reliable and secure money transfer services to Nigeria from wherever you are.</p>
                </div>
              </div>
            </div>
            <img src={EntOutreach} alt={"icon"} className="absolute m-auto left-0 right-0 top-0 bottom-0 lg:h-auto sm:h-[14rem] h-[4rem]" />
          </div>
          <hr />
          <div className="why-choose">
            <div className={"container sm:py-[4rem] py-[2rem]"}>
              <div className="flex flex-wrap font-light">
                <div className="w-8/12 flex items-center">
                  <p className="xl:text-heading-sm lg:text-heading-xs text-body-xl">EFFICIENCY</p>
                </div>
                <div className="w-4/12 flex items-center">
                  <p>Timing is essential to money transfers, that’s why we offer a fast and reliable transfer service. Your funds are received promptly and securely. Focus on what really matters and trust us to handle your money.</p>
                </div>
              </div>
            </div>
            <img src={EntEfficiency} alt={"icon"} className="absolute m-auto left-0 right-0 top-0 bottom-0 lg:h-auto sm:h-[14rem] h-[4rem]" />
          </div>
          <hr />
          <div className="why-choose">
            <div className={"container sm:py-[4rem] py-[2rem]"}>
              <div className="flex flex-wrap font-light">
                <div className="w-8/12 flex items-center">
                  <p className="xl:text-heading-sm lg:text-heading-xs text-body-xl">CONVENIENCE</p>
                </div>
                <div className="w-4/12 flex items-center">
                  <p>Sending money to Nigeria should be easy and stress-free. Entrusted has established strategic partnerships with selected countries and commercial banks in Nigeria to provide you with this seamless money transfer service.</p>
                </div>
              </div>
            </div>
            <img src={EntConvenience} alt={"icon"} className="absolute m-auto left-0 right-0 top-0 bottom-0 lg:h-auto sm:h-[14rem] h-[4rem]" />
          </div>
          <hr />
          <div className="why-choose">
            <div className={"container sm:py-[4rem] py-[2rem]"}>
              <div className="flex flex-wrap font-light">
                <div className="w-8/12 flex items-center">
                  <p className="xl:text-heading-sm lg:text-heading-xs text-body-xl">EFFORTLESS</p>
                </div>
                <div className="w-4/12 flex items-center">
                  <p>Visit any of our partner locations in your country, initiate a transfer and a token will be generated for you to share with your beneficiary for collection here in Nigeria.</p>
                </div>
              </div>
            </div>
            <img src={EntEffortless} alt={"icon"} className="absolute m-auto left-0 right-0 top-0 bottom-0 lg:h-auto sm:h-[14rem] h-[4rem]" />
          </div>
          <hr />
          <div className="why-choose">
            <div className={"container sm:py-[4rem] py-[2rem]"}>
              <div className="flex flex-wrap font-light">
                <div className="w-8/12 flex items-center">
                  <p className="xl:text-heading-sm lg:text-heading-xs text-body-xl">LOW TRANSFER FEES</p>
                </div>
                <div className="w-4/12 flex items-center">
                  <p>Every penny counts, which is why we offer you some of the lowest transfer fees in the industry. Our goal is to make our services affordable and accessible to all, without compromising on quality or security.</p>
                </div>
              </div>
            </div>
            <img src={EntLowTransfer} alt={"icon"} className="absolute m-auto left-0 right-0 top-0 bottom-0 lg:h-auto sm:h-[14rem] h-[4rem]" />
          </div>
          <div className="container mt-[4rem]">
            <div className="flex justify-center">
              <Button
                state={"SECONDARY"}
                text={"Get Started"}
              />
            </div>
          </div>
        </section>
        <section className="section-four relative sm:h-[50rem] h-[28rem]" id="aboutUs">
          <img src={EntWhoWeAreImg} alt={"icon"} className="absolute right-0 bottom-0 top-0 mt-auto xl:h-auto lg:h-[28rem] h-[22rem] sm:block hidden" />
          <div className={"container h-full"}>
            <div className={"flex flex-wrap h-full"}>
              <div className="sm:w-1/2 w-full flex items-center">
                <div className={"w-full"}>
                  <p className={"font-light sm:text-body-xl text-body-lg font-light text-white tracking-[8px]"}>
                    WHO WE ARE
                  </p>
                  <p className={"font-light lg:text-heading-sm sm:text-heading-xs text-body-xl mt-6 text-white inline-block"}>
                  We are a licensed International Money Transfer Operator that enables our clients to easily send money to their beneficiaries in Nigeria.
                  </p>
                  <div className="mt-[4rem]">
                    <Button
                      state={"PRIMARY"}
                      text={"Get Started"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-five relative sm:h-[50rem] h-[28rem]">
          <img src={Ent3dBadge} alt={"icon"} className="absolute left-0 bottom-0 top-0 my-auto xl:h-auto lg:h-[28rem] h-[22rem] sm:block hidden" />
          <div className={"container h-full"}>
            <div className={"flex justify-end flex-wrap h-full"}>
              <div className="sm:w-1/2 w-full flex items-center">
                <div className={"w-full"}>
                  <p className={"font-light sm:text-body-xl text-body-lg font-light text-white tracking-[8px]"}>
                    WHAT WE DO
                  </p>
                  <p className={"font-light lg:text-heading-sm sm:text-heading-xs text-body-xl mt-6 text-white inline-block"}>
                    We provide a secure and reliable system for our users to safely conduct their transactions in a stress-free manner.
                  </p>
                  <div className="mt-[4rem]">
                    <Button
                      state={"WHITE"}
                      text={"Get Started"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-six relative sm:py-[10rem] py-[6rem]">
          <div className={"container h-full"}>
            <div className={"flex justify-end flex-wrap h-full"}>
              <div className="w-full flex items-center">
                <div className={"w-full"}>
                  <p className={"font-light sm:text-body-xl text-body-lg font-light text-black tracking-[8px]"}>
                    PARTNERSHIPS
                  </p>
                  <p className={"font-light xl:text-[52px] lg:text-[40px] sm:text-[32px] text-[24px] mt-6 text-black inline-block xl:leading-[62px]"}>
                    Entrusted <span className="text-primary-500">strategically collaborates</span> with Nigerian commercial banks to facilitate  <span className="text-primary-500">widespread outreach</span> and ensure seamless payment settlements for recipients of transferred funds.
                  </p>
                  <p className={"font-light text-body-xl mt-6 text-black inline-block xl:w-4/12 sm:w-6/12 w-full"}>
                    We partner with proficient and reputable remittance solution companies in selected countries, to ease the burden of international money transactions for Senders.
                  </p>
                  <div className="mt-[4rem]">
                    <Button
                      state={"SECONDARY"}
                      text={"Get Started"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-seven relative xl:h-[54rem] sm:h-[40rem] h-[32rem]">
          <img src={EntPhoneMan} alt={"icon"} className="absolute right-0 xl:top-[-6rem] lg:top-[-4rem] top-[-2rem] xl:h-auto lg:h-[40rem] h-[30rem] sm:block hidden" />
          <div className={"container h-full"}>
            <div className={"flex flex-wrap h-full"}>
              <div className="sm:w-1/2 w-full flex flex-col justify-center">
                <div className={"w-full"}>
                  <p className={"xl:text-heading-sm sm:text-heading-xs text-body-xl font-light text-white"}>
                    Secure your money transfers to Nigeria with Entrusted and Say Goodbye to:
                  </p>
                  <div className={"font-light sm:text-body-xl text-body-lg mt-10 text-white  flex items-center gap-x-6"}>
                    <div className="flex items-center justify-center h-4 w-4 rounded-full border-[0.5px] border-white">
                      <img src={EntCheck} alt="icon" className="h-3" />
                    </div>
                    Transaction delays
                  </div>
                  <div className={"font-light sm:text-body-xl text-body-lg mt-4 text-white  flex items-center gap-x-6"}>
                    <div className="flex items-center justify-center h-4 w-4 rounded-full border-[0.5px] border-white">
                      <img src={EntCheck} alt="icon" className="h-3" />
                    </div>
                    High exchange rates
                  </div>
                  <div className={"font-light sm:text-body-xl text-body-lg mt-4 text-white  flex items-center gap-x-6"}>
                    <div className="flex items-center justify-center h-4 w-4 rounded-full border-[0.5px] border-white">
                      <img src={EntCheck} alt="icon" className="h-3" />
                    </div>
                    Limited currency exchange options
                  </div>
                  <div className={"font-light sm:text-body-xl text-body-lg mt-4 text-white  flex items-center gap-x-6"}>
                    <div className="flex items-center justify-center h-4 w-4 rounded-full border-[0.5px] border-white">
                      <img src={EntCheck} alt="icon" className="h-3" />
                    </div>
                    Challenges aiding distant loved ones
                  </div>
                  <div className="mt-[4rem]">
                    <Button
                      state={"WHITE"}
                      text={"Get Started"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-eight relative xl:pt-[8rem] pt-[4rem] xl:pb-[4rem] pb-[2rem]">
          <div className={"container h-full"}>
            <p className={"font-light xl:text-[36px] lg:text-[24px] sm:text-[18px] text-[16px] mt-6 text-black text-center sm:px-16 px-8"}>
             Entrusted  <span className="text-primary-500">strategically collaborates</span> With Nigerian commercial banks to facilitate <span className="text-primary-500">widespread outreach</span> and ensure seamless payment settlements for recipients of transferred funds.
            </p>
            <div className="grid sm:grid-cols-3 grid-cols-2 gap-x-10 mt-16">
              <div className="sm:block hidden">
                <img src={EntPhoneCode} alt={"icon"} className="lg:h-auto h-[26rem]" />
              </div>
              <div className="col-span-2 sm:pr-16">
                <hr />
                <div className="grid grid-cols-2 sm:gap-x-20 gap-x-10 sm:mt-16 mt-8">
                  <div className="w-full">
                    <p className={"font-light text-body-xl text-black tracking-[8px]"}>
                      OUR MISSION
                    </p>
                    <p className={"font-light sm:text-body-xl text-body-md mt-4 text-black inline-block"}>
                      Providing a trusted international money transfer ecosystem that enables ease in transaction between transferors and beneficiaries as well as satisfying the needs of all stakeholders.
                    </p>
                  </div>
                  <div className="w-full">
                    <p className={"font-light text-body-xl text-black tracking-[8px]"}>
                      OUR VISION
                    </p>
                    <p className={"font-light sm:text-body-xl text-body-md mt-4 text-black inline-block"}>
                      To be the most trusted International Money Transfer Service Provider delivering remittances to all beneficiaries across Nigeria.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-nine relative lg:pt-[8rem] pt-[4rem] lg:pb-[4rem] pb-[2rem]" id="contactUs">
          <div className={"container h-full flex flex-col items-center"}>
            <p className={"font-light text-body-xl text-primary-500 text-center tracking-[8px]"}>
              OUR VALUES
            </p>
            <div className="lg:w-8/12 sm:w-10/12 w-full mt-20">
              <div className="grid sm:grid-cols-4 grid-cols-2">
                <div className="flex flex-col items-center justify-center">
                  <img src={EntInnovation} alt={"icon"} className="h-[4rem]" />
                  <ImageLabel label={'Innovation'} />
                </div>
                <div className="flex flex-col items-center justify-center">
                  <img src={EntTrust} alt={"icon"} className="h-[4rem]" />
                  <ImageLabel label={'Trust'} />
                </div>
                <div className="sm:mt-0 mt-16 flex flex-col items-center justify-center">
                  <img src={EntIntegrity} alt={"icon"} className="h-[4rem]" />
                  <ImageLabel label={'Integrity'} />
                </div>
                <div className="sm:mt-0 mt-16 flex flex-col items-center justify-center">
                  <img src={EntCc} alt={"icon"} className="h-[4rem]" />
                  <ImageLabel label={'Customer centricity'} />
                </div>
                <div className="flex flex-col items-center justify-center mt-24">
                  <img src={EntTeamwork} alt={"icon"} className="h-[4rem]" />
                  <ImageLabel label={'Teamwork'} />
                </div>
                <div className="flex flex-col items-center justify-center mt-24">
                  <img src={EntPartnerShip} alt={"icon"} className="h-[4rem]" />
                  <ImageLabel label={'Partnership'} />
                </div>
                <div className="flex flex-col items-center justify-center mt-24">
                  <img src={EntEs} alt={"icon"} className="h-[4rem]" />
                  <ImageLabel label={'Excellent Service'} />
                </div>
              </div>
              <div className="flex justify-center my-20">
                <StaticButton state={"SECONDARY"} text={"Get Started"} />
              </div>
            </div>
          </div>
        </section>
        <section className="section-nine relative lg:pt-[8rem] pt-[4rem] lg:pb-[4rem] pb-[2rem]" id="contactUs">
          <div className={"container h-full flex flex-col items-center"}>
            <p className={"font-light text-body-xl text-center tracking-[8px]"}>
              OUR LEADERSHIP TEAM
            </p>
            <div className="lg:w-8/12 sm:w-10/12 w-full mt-10 mb-24">
              <div className="grid sm:grid-cols-3 grid-cols-2">
                <div className="flex flex-col items-center mt-14 relative">
                  <div className="h-[150px] w-[151px] relative">
                    <img src={EntLdEc} alt={"icon"} />
                    <a href="#/" className="linked-in font-avantMd">in</a>
                  </div>
                  <div className={"text-center mt-8"}>
                    <p className="text-[16px] font-bold font-avantMd">Mahmood Ahmadu</p>
                    <p className="text-[14px]">Executive Chairman</p>
                  </div>
                </div>
                <div className="flex flex-col items-center mt-14">
                  <div className="h-[150px] w-[151px] relative">
                    <img src={EntLdEd} alt={"icon"} />
                    <a href="#/" className="linked-in font-avantMd">in</a>
                  </div>
                  <div className={"text-center mt-8"}>
                    <p className="text-[16px] font-bold font-avantMd">Anthony Nwachukwu</p>
                    <p className="text-[14px]">Executive Director</p>
                  </div>
                </div>
                <div className="flex flex-col items-center mt-14 relative">
                  <div className="h-[150px] w-[151px] relative">
                    <img src={EntLdCs} alt={"icon"} />
                    <a href="#/" className="linked-in font-avantMd">in</a>
                  </div>
                  <div className={"text-center mt-8"}>
                    <p className="text-[16px] font-bold font-avantMd">Kassim Gidado</p>
                    <p className="text-[14px]">Chief of Staff</p>
                  </div>
                </div>
                <div className="flex flex-col items-center mt-14 relative">
                  <div className="h-[150px] w-[151px] relative">
                    <img src={EntLdBa} alt={"icon"} />
                    <a href="#/" className="linked-in font-avantMd">in</a>
                  </div>
                  <div className={"text-center mt-8"}>
                    <p className="text-[16px] font-bold font-avantMd">Abi Haruna</p>
                    <p className="text-[14px]">Board Advisor, General Counsel<br />& Company Secretary</p>
                  </div>
                </div>
                {/*<div className="flex flex-col items-center mt-14 relative">
                  <div className="h-[150px] w-[151px] relative">
                    <img src={EntLdHc} alt={"icon"} />
                    <a href="#/" className="linked-in font-avantMd">in</a>
                  </div>
                  <div className={"text-center mt-8"}>
                    <p className="text-[16px] font-bold font-avantMd">Imade George-Ogbonna</p>
                    <p className="text-[14px]">Head of Compliance<br />and Risk Management</p>
                  </div>
                </div>*/}
                <div className="flex flex-col items-center mt-14 relative">
                  <div className="h-[150px] w-[151px] relative">
                    <img src={EntLdGm} alt={"icon"} />
                    <a href="#/" className="linked-in font-avantMd">in</a>
                  </div>
                  <div className={"text-center mt-8"}>
                    <p className="text-[16px] font-bold font-avantMd">Tahir Bamanga</p>
                    <p className="text-[14px]">General Manager</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Main>
  );
};

export default Home;
